<template>
  <div class="yElevenTime">
    距活动结束仅剩
    <span v-if="countDownDay!=='00'">{{ countDownDay }}</span>{{ countDownDay !== '00' ? '天' : '' }} <span>{{ countDownHour }}</span>时<span>{{ countDownMinute }}</span>分<span> {{ countDownSecond }}</span>秒
  </div>
</template>
<script>
/* eslint-disable eqeqeq */
import { Toast } from 'vant'
export default {
  name: 'Countdown',
  props: {
    timer: {
      type: String || Number,
      default: null
    }
    // starttime: {
    //   type: String,
    //   default: () => {
    //     let date = new Date(new Date().getTime() + 24 * 60 * 60 * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    //     let Y = date.getFullYear() + "-";
    //     let M =
    //       (date.getMonth() + 1 < 10
    //         ? "0" + (date.getMonth() + 1)
    //         : date.getMonth() + 1) + "-";
    //     let D = date.getDate() + " ";
    //     let h = date.getHours() + ":";
    //     let m = date.getMinutes() + ":";
    //     let s = date.getSeconds();
    //     return Y + M + D + h + m + s;
    //   },
    // },
    // endtime: {
    //   type: String,
    //   default: () => {
    //     let date = new Date(new Date().getTime());
    //     let Y = date.getFullYear() + "-";
    //     let M =
    //       (date.getMonth() + 1 < 10
    //         ? "0" + (date.getMonth() + 1)
    //         : date.getMonth() + 1) + "-";
    //     let D = date.getDate() + " ";
    //     let h = date.getHours() + ":";
    //     let m = date.getMinutes() + ":";
    //     let s = date.getSeconds();
    //     return Y + M + D + h + m + s;
    //   },
    // },
  },
  data() {
    return {
      countDownDay: '00',
      countDownHour: '00',
      countDownMinute: '00',
      countDownSecond: '00'
    }
  },
  mounted() {
    this.startTimer(this.timer)
  },
  methods: {
    // 双十一倒计时 不考虑三位数
    startTimer(totalSecond) {
      if (totalSecond === '0' || totalSecond === 0) {
        return
      }
      // 倒计时

      var interval = setInterval(
        function() {
          // 秒数
          var second = totalSecond

          // 天
          var dayer = parseInt(second / 3600.0 / 24)
          var dayStr = dayer.toString()
          if (dayStr.length == 1) dayStr = '0' + dayStr
          // 小时位
          var hr = parseInt((parseFloat(second / 3600.0 / 24) - parseInt(second / 3600.0 / 24)) * 24)

          var hrStr = hr.toString()
          if (hrStr.length == 1) hrStr = '0' + hrStr
          // 分钟位

          var min = parseInt((parseFloat(second / 3600.0) - parseInt(second / 3600.0)) * 60)
          var minStr = min.toString()
          if (minStr.length == 1) minStr = '0' + minStr
          // 秒位
          var sec = parseInt((parseFloat((parseFloat(second / 3600.0) - parseInt(second / 3600.0)) * 60) - parseInt((parseFloat(second / 3600.0) - parseInt(second / 3600.0)) * 60)) * 60)
          var secStr = sec.toString()
          if (secStr.length == 1) secStr = '0' + secStr
          this.countDownDay = dayStr
          this.countDownHour = hrStr
          this.countDownMinute = minStr
          this.countDownSecond = secStr
          totalSecond--
          if (totalSecond < 0) {
            Toast('秒杀商品已抢光')
            clearInterval(interval)
            setTimeout(() => {
              window.history.go(-1)
            }, 1000)
            this.countDownHour = '00'
            this.countDownMinute = '00'
            this.countDownSecond = '00'
          }
        }.bind(this),
        1000
      )

      // var interval = setInterval(
      //   function () {
      //     // 秒数
      //     var second = totalSecond;

      //     // 天
      //    var dayer =  Math.floor(second / (24*60*60))
      //      var dayStr = dayer.toString()
      //     // 小时位
      //     var hr = Math.floor((second - dayer) / 3600);
      //     //  var hr = Math.floor(second / 3600);
      //     var hrStr = hr.toString();
      //     if (hrStr.length == 1) hrStr = "0" + hrStr;
      //     // 分钟位

      //     var min = Math.floor((second - hr * 3600) / 60);
      //     var minStr = min.toString();
      //     if (minStr.length == 1) minStr = "0" + minStr;
      //     // 秒位
      //     var sec = second - hr * 3600 - min * 60;
      //     var secStr = sec.toString();
      //     if (secStr.length == 1) secStr = "0" + secStr;
      //     this.countDownDay= dayStr
      //     this.countDownHour = hrStr;
      //     this.countDownMinute = minStr;
      //     this.countDownSecond = secStr;
      //     totalSecond--;
      //     if (totalSecond < 0) {
      //       Toast("秒杀商品已抢光");
      //       clearInterval(interval);
      //       setTimeout(() => {
      //         window.history.go(-1);
      //       }, 1000);
      //       this.countDownHour = "00";
      //       this.countDownMinute = "00";
      //       this.countDownSecond = "00";
      //     }
      //   }.bind(this),
      //   1000
      // );
    }
  }
}
</script>
<style lang="less" scoped>
.yElevenTime {
  font-size: 13px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: 500;
  color: #ffffff;
  line-height: 33px;
  padding: 16.5px 0;

  span {
    vertical-align: middle;
    padding-left: 4px;
    min-width: 40px;
    height: 30px;
    font-size: 22px;
    letter-spacing: 6px;
    display: inline-block;
    // background: #000;
    text-align: center;
    line-height: 28px;
    background: url("../../../assets/images/timeBg.png") repeat-x;
    background-size: 100% 100%;
  }
}
</style>
