<template>
  <div>
    <div class="main">
      <div v-if="vebueData" class="yElevenBox">
        <div class="yElevenBanner">
          <img src="@/assets/images/ybanner.jpg">
        </div>
        <div class="yElevenTimeBox">
          <CountDown :timer="vebueData.countdown" />
          <ul class="PromoteBtns">
            <li @click="gotoUser">
              <img src="@/assets/images/PromoteBtn1.png">
            </li>
            <li @click="gotoRichList">
              <img src="@/assets/images/PromoteBtn2.png">
            </li>
            <li @click="gomyteam">
              <img src="@/assets/images/PromoteBtn3.png">
            </li>
          </ul>
        </div>

        <div class="publicTitle">
          <!-- <img src="@/assets/images/BrandDis.png" /> -->
          超级分会场
        </div>

        <ul class="BoutiqueBox">
          <li
            v-for="(featured, index) in vebueData.featuredVenues"
            :key="index"
            @click="gotoVenue(featured)"
          >
            <img :src="featured.images">
          </li>
        </ul>

        <div class="publicTitle">
          优惠券专区
          <!-- <img src="@/assets/images/coupon.png" /> -->
        </div>
        <CouponList :data="vebueData.coupon" style="padding: 0 7px" />
        <van-tabs
          v-model="active"
          scrollspy
          sticky
          line-height="0px"
          title-active-color="#FF1C57"
        >
          <van-tab title="甄选美妆">
            <div class="publicTitle">
              <!-- <img src="@/assets/images/BoutiqueVenue.png" />
               -->
              甄选美妆
            </div>

            <ul class="BrandBox">
              <li
                v-for="(brand, index) in brandTop"
                :key="index"
                :class="index % 2 ? 'picout' : 'picin'"
                @click="goPage(brand)"
              >
                <div class="picBg" :class="'picBg' + index">
                  <img :src="brand.images">
                </div>
              </li>

              <li
                v-for="(brandB, j) in brandBottom"
                :key="j + 4"
                :class="j % 2 ? 'picin' : 'picout'"
                @click="goPage(brandB)"
              >
                <div class="picBg" :class="'picBg' + j + 4">
                  <img :src="brandB.images">
                </div>
              </li>
            </ul>
          </van-tab>
          <van-tab title="限时秒杀">
            <div class="publicTitle">
              限时秒杀
              <!-- <img src="@/assets/images/Spike.png" /> -->
            </div>

            <ul class="tabsPublicUl">
              <li
                v-for="(exp, index) in vebueData.explosiveSpike"
                :key="index"
                class="tabLiBox"
                @click="godetail(exp, 'explosiveSpikeId')"
              >
                <div class="tabImgBox">
                  <img class="img" :src="exp.goodCover">
                </div>
                <h4>{{ exp.goodName }}</h4>
                <div class="tabPriceBox">
                  ¥{{ exp.goodVipPrice }}<span>¥{{ exp.goodPrice }}</span>
                </div>
              </li>
            </ul>
          </van-tab>
          <van-tab title="第2件0元">
            <div class="publicTitle">
              单品第2件0元
              <!-- <img src="@/assets/images/BigDiscount.png" /> -->
            </div>
            <ul class="tabsPublicUl">
              <li
                v-for="(coupons, index) in vebueData.largeCoupons"
                :key="index"
                class="tabLiBox"
                @click="godetail(coupons, 'largeCouponsId')"
              >
                <div class="tabImgBox">
                  <img class="img" :src="coupons.goodCover">
                </div>
                <h4>{{ coupons.goodName }}</h4>
                <div class="tabPriceBox">
                  ¥{{
                    coupons.goodVipPrice
                  }}<span>¥{{ coupons.goodPrice }}</span>
                </div>
              </li>
            </ul>
          </van-tab>

          <!-- <van-tab title="拼团免单">
            <div class="publicTitle">
              拼团免单
            </div>
            <ul class="tabsPublicUl">
              <li
                v-for="(enjoy, index) in vebueData.enjoyEarning"
                :key="index"
                class="tabLiBox"
                @click="godetail(enjoy, 'enjoyEarningId')"
              >
                <div class="tabImgBox">
                  <img class="img" :src="enjoy.goodCover" />
                </div>
                <h4>{{ enjoy.goodName }}</h4>
                <div class="tabPriceBox">
                  ¥{{ enjoy.goodVipPrice }}<span>¥{{ enjoy.goodPrice }}</span>
                </div>
              </li>
            </ul></van-tab> -->

          <van-tab title="超级权益">
            <div class="publicTitle">
              <!-- <img src="@/assets/images/Popular.png" /> -->

              超级权益
            </div>
            <ul class="tabsPublicUl">
              <!-- @click="godetail(hot)" -->
              <li
                v-for="(hot, index) in vebueData.hotSale"
                :key="index"
                class="tabLiBox"
                @click="godetail(hot, 'hotSaleId')"
              >
                <!-- {{hot}} -->
                <div class="tabImgBox">
                  <img class="img" :src="hot.goodCover">
                </div>
                <h4>{{ hot.goodName }}</h4>
                <div class="tabPriceBox">
                  ¥{{ hot.goodVipPrice }}<span>¥{{ hot.goodPrice }}</span>
                </div>
              </li>
            </ul>
          </van-tab>
        </van-tabs>
        <h4 class="copyright" />
        <!-- <MiniCart :goodsStatus="2" :goodinfo="goodinfo" /> -->
      </div>
    </div>
  </div>
</template>
<script>
import CountDown from '../component/Countdown'
import CouponList from '../component/CouponList'
import { getJumpUrlNew } from '@/services/api'
import { urlConversion, getBrowserAgent, getClientInfo } from '@/utils/public.js'

// import MiniCart from "@/component/GoodsDetail/MiniCart";

import Vue from 'vue'
import { mainVenueList } from '@/services/activity.js'

import { Tab, Tabs } from 'vant'
import { wxConfigInit } from '@/utils/wx-api'

Vue.use(Tab)
Vue.use(Tabs)
export default {
  name: 'Index',
  components: {
    CountDown,
    CouponList
    // MiniCart,
  },
  data() {
    return {
      brandTop: [],
      brandBottom: [],
      goodinfo: null,
      headcolor: '#fff', // 头部标题颜色
      countDownHour: '00',
      countDownMinute: '00',
      countDownSecond: '00',
      active: 0,
      vebueData: null,
      couponList: [],
      jumpTitle: ['精品会场 ', '爆款秒杀', '大额优惠', '乐享赚', '全网热销'],
      BoutiqueBg: [
        require('@/assets/images/Boutique1.png'),
        require('@/assets/images/Boutique2.png'),
        require('@/assets/images/Boutique3.png'),
        require('@/assets/images/Boutique4.png'),
        require('@/assets/images/Boutique3.png'),
        require('@/assets/images/Boutique6.png')
      ]
    }
  },
  created() {
    // window.RNLoginCallBack = this.RNLoginCallBack;
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function('event.returnValue=false')
      // 禁用选择
      document.onselectstart = new Function('event.returnValue=false')
    })
  },
  mounted() {
    window.RNshareDataChange = this.RNshareDataChange
    this.mainVenueList()
    // this.RNshareDataChange();
  },
  methods: {
    RNshareDataChange() {
      this.$nextTick(() => {
        const mid = this.$route.query.mid

        const obj = urlConversion('obj', window.location.href)
        const arr = []
        let _url = ''
        for (const key in obj.value) {
          if (key !== 'env') {
            arr.push(key + '=' + obj.value[key])
          }
        }
        _url = obj.Http + '?' + arr.join('&')

        if (mid && mid !== '0') {
          if (this.vebueData) {
            const _Router = {
              eventName: 'share',
              url: _url,
              image: this.vebueData.shareData.image,
              title: this.vebueData.shareData.title,
              des: this.vebueData.shareData.desc
              // path:'',
            }
            this.$store.dispatch('RNrouter', _Router)
          } else {
            const RNloginData = {
              eventName: 'login'
            }
            this.$store.dispatch('RNrouter', RNloginData)
          }
        } else {
          const RNloginData = {
            eventName: 'login'
          }
          this.$store.dispatch('RNrouter', RNloginData)
        }
      })
    },
    goPage(row) {
      // type 1 直接拼 MID  ENV 99 直营

      // type 非1 都请求转链接口

      // 直营

      if (row.type === '1') {
        if (getBrowserAgent('isWeiXin')) {
          window.location.href = row.url
        } else {
          // 在移动端跳转
          if (getClientInfo()) {
            // http://web.yuetao.vip/yEleven?env=99&mid=30536
            const Mid = this.$route.query.mid
            let urlStr = ''
            if (row.url.indexOf('?') !== -1) {
              urlStr =
                row.url +
                '&env=' + this.$route.query.env + '&mid=' +
                Mid +
                '&codeNumber=' +
                this.vebueData.codeNumber
            } else {
              urlStr =
                row.url +
                '?env=' + this.$route.query.env + '&mid=' +
                Mid +
                '&codeNumber=' +
                this.vebueData.codeNumber
            }

            const _Router = {
              name: row.name,
              redirectType: 111,
              redirectUrl: urlStr
            }
            // 这里是跳转RN
            this.$store.dispatch('RNrouter', _Router)
          } else {
            window.location.href = row.url
          }
        }
      } else {
        const body = {
          url: urlConversion('str', row.url),
          uid: localStorage.getItem('uid')
        }
        getJumpUrlNew(body).then((res) => {
          if (Number(res.code) === 200) {
            window.location.href = res.data.jumpLink
          }
        })
      }
    },
    gotoVenue(row) {
      if (getBrowserAgent('isWeiXin')) {
        this.$store.commit('shareDataChange', row.shareData)
        const arr = row.url.split('?')
        this.$router.push(
          '/bVenue?' + arr[1] + '&' + urlConversion('str') + '&pageType=two'
        )
      } else {
        if (getClientInfo()) {
          // 这里是跳转RN
          const mid = this.$route.query.mid
          const _Router = {
            redirectType: 111,
            name: row.name,
            redirectUrl:
              row.redirectUrl +
              '&env=' + this.$route.query.env + '&mid=' +
              mid +
              '&codeNumber=' +
              this.vebueData.codeNumber +
              '&pageType=two',
            shareData: row.shareData
          }
          this.$store.dispatch('RNrouter', _Router)
        } else {
          this.$store.commit('shareDataChange', row.shareData)
          const arr = row.url.split('?')
          this.$router.push(
            '/bVenue?' + arr[1] + '&' + urlConversion('str') + '&pageType=two'
          )
        }
      }
    },
    showSku(goodinfo) {
      this.goodinfo = goodinfo
      this.$store.commit('changeShowSku', true)
    },
    gotoUser() {
      if (getBrowserAgent('isWeiXin')) {
        const mid = window.localStorage.getItem('uid')
        if (mid) {
          this.$router.push({
            path: '/user'
          })
        } else {
          this.$store.dispatch('loginIntercept', this.$route.query)
        }
      } else {
        if (getClientInfo()) {
          const Mid = this.$route.query.mid
          if (Mid && Mid !== '0') {
            // 这个方法？
            const _Router = {
              path: 'router://cardVoucher' // 优惠券列表
            }
            this.$store.dispatch('RNrouter', _Router)
            // 这里是跳转RN
          } else {
            const RNloginData = {
              eventName: 'login'
            }
            this.$store.dispatch('RNrouter', RNloginData)
          }
        } else {
          const mid = window.localStorage.getItem('uid')
          if (mid) {
            this.$router.push({
              path: '/user'
            })
          } else {
            this.$store.dispatch('loginIntercept', this.$route.query)
          }
        }
      }
    },
    // 跳转到我的团队
    gomyteam() {
      if (getBrowserAgent('isWeiXin')) {
        const mid = window.localStorage.getItem('uid')
        if (mid) {
          this.$router.push({
            path: '/myTeam'
          })
        } else {
          this.$store.dispatch('loginIntercept', this.$route.query)
        }
      } else {
        if (getClientInfo()) {
          const Mid = this.$route.query.mid
          if (Mid && Mid !== '0') {
            // let urlStr = "http://10.168.1.194/myTeam?env=99&mid=" + Mid;
            const urlStr = 'https://web.yuetao.vip/myTeam?env=' + this.$route.query.env + '&mid=' + Mid
            const _Router = {
              name: '我的团队',
              redirectType: 111,
              redirectUrl: urlStr
            }
            // 这里是跳转RN
            this.$store.dispatch('RNrouter', _Router)
          } else {
            const RNloginData = {
              eventName: 'login'
            }
            this.$store.dispatch('RNrouter', RNloginData)
          }

          // window.ReactNativeWebView.postMessage(JSON.stringify(_Router));
        } else {
          const mid = window.localStorage.getItem('uid')
          if (mid) {
            this.$router.push({
              path: '/myTeam'
            })
          } else {
            this.$store.dispatch('loginIntercept', this.$route.query)
          }
        }
      }
    },
    // 跳转创富榜
    gotoRichList() {
      if (getBrowserAgent('isWeiXin')) {
        const mid = window.localStorage.getItem('uid')
        if (mid) {
          this.$router.push({
            path: '/richList'
          })
        } else {
          this.$store.dispatch('loginIntercept', this.$route.query)
        }
      } else {
        if (getClientInfo()) {
          const Mid = this.$route.query.mid
          if (Mid && Mid !== '0') {
            const urlStr = 'https://web.yuetao.vip/richList?env=' + this.$route.query.env + '&mid=' + Mid

            const _Router = {
              name: '悦淘创富榜',
              redirectType: 111,
              redirectUrl: urlStr
            }
            // 这里是跳转RN
            this.$store.dispatch('RNrouter', _Router)
            // window.ReactNativeWebView.postMessage(JSON.stringify(_Router));
          } else {
            const RNloginData = {
              eventName: 'login'
            }
            this.$store.dispatch('RNrouter', RNloginData)
            // window.ReactNativeWebView.postMessage(JSON.stringify(RNloginData));
          }
        } else {
          const mid = window.localStorage.getItem('uid')
          if (mid) {
            this.$router.push({
              path: '/richList'
            })
          } else {
            this.$store.dispatch('loginIntercept', this.$route.query)
          }
        }
      }
    },
    // 跳转到详情
    godetail(item, keyid) {
      item.activityId = this.vebueData[keyid]
      this.$store.dispatch('godetail', item)
    },

    // 获取会场信息
    mainVenueList() {
      let uid

      if (getBrowserAgent('isWeiXin')) {
        uid = localStorage.getItem('uid')
      } else {
        if (getClientInfo()) {
          uid = this.$route.query.mid
        } else {
          uid = localStorage.getItem('uid')
        }
      }

      const body = {
        // activity_id: 75,
      }
      if (uid) {
        body.member_id = uid
      }

      mainVenueList(body).then((res) => {
        if (Number(res.code) === 200) {
          const data = res.data
          this.vebueData = data
          if (res.data.brandDiscount && res.data.brandDiscount.length !== 0) {
            this.brandTop = res.data.brandDiscount.filter((e, index) => {
              return index < 4
            })
            this.brandBottom = res.data.brandDiscount.filter((e, index) => {
              return index >= 4
            })
          }

          // if (this.$route.query.env !== "99") {

          const obj = urlConversion('obj', window.location.href)
          const arr = []
          let _url = ''
          for (const key in obj.value) {
            if (key !== 'env') {
              arr.push(key + '=' + obj.value[key])
            }
          }
          _url = obj.Http + '?' + arr.join('&')

          // let url = window.location.href + "?codeNumber=" + res.data.codeNumber;
          const shareInfo = {
            title: res.data.shareData.title, // 分享标题
            desc: res.data.shareData.des, // 分享描述
            link: _url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: res.data.shareData.image // 分享图标
          }
          wxConfigInit(shareInfo)
          // this.$store.dispatch('wxConfigInit', shareInfo)
          // }
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
#app {
  height: 100%;
}

html,
body {
  height: 100%;
}

.yElevenBox {
  height: 100%;
  // overflow: scroll;
  background: #eb003f;
  width: 375px;
  // height: 100vh;
  .yElevenBanner {
    width: 375px;
    height: 383px;
    // background: url("../../../assets/images/ybannerBg.gif");
    background-size: 100%;
    // background: #fff;
  }

  .yElevenTimeBox {
    width: 100%;
    padding: 7px;
    background: url("../../../assets/images/y.png") repeat-x;
    background-size: 100%;

    .PromoteBtns {
      width: 100%;
      height: 75px;
      // background: #fff;
      display: flex;
      justify-content: space-between;

      li {
        width: 116.5px;
        height: 65px;

        img {
          width: 100%;
        }
      }
    }
  }

  .publicTitle {
    // height: 60px;
    margin: 0 0 10px 0;

    width: 100%;
    height: 60px;
    text-align: center;
    background: url("../../../assets/images/bvenueTitle.png") no-repeat;
    background-size: 100%;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    line-height: 45px;
    font-family: PingFang SC Medium, PingFang SC Medium-Medium;
    letter-spacing: 2px;
  }

  .BrandBox {
    width: 100%;
    padding: 0 7px;
    // height: 75px;
    display: flex;
    flex-flow: row wrap;
    justify-content: left;

    li {
      margin-right: 15px;

      &:nth-child(4n + 0) {
        margin-right: 0px;
      }

      width: 79px;
      height: 79px;
      margin-bottom: 20px;
      border-radius: 17px;
      overflow: hidden;

      .BrandTop {
        width: 100%;
        height: 59px;
        background: #fff;
        border-radius: 17px 17px 5px 5px;
        overflow: hidden;
        position: relative;

        .BrandTag {
          position: absolute;
          bottom: 5px;
          left: 0;
          right: 0;
          margin: 0 auto;
          background: #ea3622;
          width: 60px;
          height: 15px;
          color: #fff;
          font-family: PingFang SC Regular, PingFang SC Regular-Regular;
          font-weight: 400;
          font-size: 8.5px;
          border-radius: 15px;
          line-height: 15px;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      h4 {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-top: 3px;
        font-size: 11px;
        color: #fff;
        font-family: Lantinghei SC Demibold, Lantinghei SC Demibold-Demibold;
        color: #fffffe;
      }
    }

    .picBg {
      width: 100%;
      height: 79px;
    }

    .picBg1 {
      background: linear-gradient(96deg, #eb3e88 0%, #ed645b 100%);
    }

    .picBg2 {
      background: linear-gradient(91deg, #892ee8 0%, #ec61c8 100%);
    }

    .picBg3 {
      background: linear-gradient(96deg, #c23ed9 0%, #ee8660 100%);
    }

    .picBg4 {
      background: linear-gradient(96deg, #83c2ea 0%, #e469cb 100%);
    }

    .picBg5 {
      background: linear-gradient(94deg, #ee8464 0%, #ba31ee 100%);
    }

    .picBg6 {
      background: linear-gradient(85deg, #7cc8ec 0%, #ec61c8 100%);
    }

    .picBg7 {
      background: linear-gradient(88deg, #eb4381 0%, #ed645b 100%);
    }

    .picBg8 {
      background: linear-gradient(86deg, #8e30e7 0%, #ed61c8 100%);
    }
  }

  .BoutiqueBox {
    width: 100%;
    padding: 0 7px;
    display: flex;
    flex-flow: row wrap;
    justify-content: left;

    li {
      margin-right: 7px;

      &:nth-child(3n + 0) {
        margin-right: 0px;
      }

      overflow: hidden;
      width: 115.5px;
      height: 150px;
      margin-bottom: 5.5px;
      position: relative;
      // background: #fff;
      // img{
      //   height: 100%;
      //   width: auto;
      // }
      .BoutiqueTitleBox {
        position: relative;
        z-index: 2;
        color: #fff;
        text-align: left;
        padding: 20px 10px;
        line-height: 20px;

        h2 {
          font-size: 16px;
          font-weight: bold;
        }

        h3 {
          font-size: 14px;
          font-weight: bold;
        }
      }

      .BoutiqueImg {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        text-align: center;
      }
    }
  }

  .tabsPublicUl {
    width: 100%;
    padding: 0 7px;
    display: flex;
    flex-flow: row wrap;
    justify-content: left;

    li {
      margin-right: 9px;

      &:nth-child(3n + 0) {
        margin-right: 0px;
      }

      background: #fff;
      width: 113px;
      height: 187px;
      margin-bottom: 9px;
      border-radius: 10px;
      overflow: hidden;
    }

    .tabLiBox {
      .tabImgBox {
        width: 100%;
        height: 112px;
        overflow: hidden;
      }

      h4 {
        font-size: 10px;
        font-family: PingFang SC Regular, PingFang SC Regular-Regular;
        font-weight: 500;
        text-align: left;
        color: #010101;
        padding: 5.5px 5.5px 5.5px 5.5px;
        height: 32px;
        overflow: hidden;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        line-height: 14px;
      }

      .tabPriceBox {
        text-align: left;
        padding: 3px 5.5px;
        font-size: 15px;
        color: #e33f44;

        span {
          margin-left: 5px;
          font-size: 11px;
          font-family: PingFang SC Regular, PingFang SC Regular-Regular;
          font-weight: 400;
          text-decoration: line-through;
          text-align: left;
          color: #999999;
        }
      }
    }
  }

  .copyright {
    color: #fff;
    padding: 30px 0 0 0;
  }
}

.picout {
  -webkit-animation: scaleout 5.3s infinite linear;
  animation: scaleout 5.3s infinite linear;
}

.picin {
  -webkit-animation: scalein 5.3s infinite linear;
  animation: scalein 5.3s infinite linear;
}

@keyframes scaleout {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }

  25% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }

  50% {
    transform: scale(1.18);
    -webkit-transform: scale(1.18);
  }
  75% {
    transform: scale(1.18);
    -webkit-transform: scale(1.18);
  }

  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@keyframes scalein {
  0% {
    transform: scale(1.18);
    -webkit-transform: scale(1.18);
  }
  25% {
    transform: scale(1.18);
    -webkit-transform: scale(1.18);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  75% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  100% {
    transform: scale(1.18);
    -webkit-transform: scale(1.18);
  }
}

/deep/ .van-tabs__wrap {
  height: 36px;

  .van-tabs__nav {
    background: #cd1c34 !important;

    .van-tab {
      color: #fff;
    }

    .van-tab--active {
      color: #fff !important;
      background: #ff1c57;
    }
  }
}

/deep/ .van-tabs__wrap {
  &::after {
    // border: 0 solid #ebedf0;
    border: none;
  }
}
</style>
