<template>
  <div>
    <ul v-if="data" class="couponBox">
      <li
        v-for="(itme, index) in data"
        :key="index"
        :class="index < 4 ? 'CouponLibgA' : 'CouponLibgB'"
      >
        <div :class="itme.couponStatus ? 'couponLiBox' : 'NoCouponLiBox'">
          <div v-if="!itme.isReceiveCoupon" class="couponStatus2">
            <img src="@/assets/images/couponStatus2.png">
          </div>
          <p class="CouponAmount">
            <span>{{ itme.amount }}</span>元
          </p>
          <p v-if="itme.type === 0" class="CouponCondition">
            ·满{{ itme.samount }}-{{ itme.amount }}·
          </p>
          <p class="CouponSub">{{ itme.name }}</p>
          <div
            v-if="itme.couponStatus"
            class="CouponIcon"
            style="background: #dd2f27"
            @click="gotoReceive(itme, index)"
          >
            {{ itme.isReceiveCoupon ? '立即领取' : '立即使用' }}
          </div>
          <div v-else class="CouponIcon" style="background: #adadad">
            已抢光
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import Vue from 'vue'
import { Toast } from 'vant'
import { postVouchers, useHallCoupon } from '@/services/activity'
import { urlConversion, getBrowserAgent } from '@/utils/public.js'

Vue.use(Toast)
export default {
  name: 'CouponList',
  props: {
    data: {
      type: Array,
      default: null
    }
  },
  methods: {
    // 领取优惠券
    gotoReceive(row, index) {
      let uid = ''

      if (getBrowserAgent('isWeiXin')) {
        uid = localStorage.getItem('uid')
      } else {
        if (this.$route.query.env === '99') {
          uid = this.$route.query.mid
          if (!uid || uid === '0') {
            const _Router = {
              eventName: 'login'
            }
            this.$store.dispatch('RNrouter', _Router)
            return
          }
        } else {
          uid = localStorage.getItem('uid')
        }
      }

      if (uid && uid !== '0') {
        const setItme = row
        if (row.isReceiveCoupon) {
          // Toast("优惠券领取成功！");
          const opt = {
            // uid: window.localStorage.getItem("uid") || "",
            uid: uid,
            cid: setItme.id
          }

          // 拦截器requsts
          postVouchers(opt).then((res) => {
            if (Number(res.code) === 200) {
              setItme.isReceiveCoupon = false
              this.$set(this.data, index, setItme)
              Toast(res.msg)
            } else if (Number(res.code) === 400) {
              Toast('400')
            }
          })
        } else {
          this.coumponClick(row)
        }
      } else {
        this.$store.dispatch('loginIntercept', this.$route.query)
      }
    },

    // 优惠卷点击事件
    coumponClick(data) {
      // return
      if (this.$route.query.venueId) {
        this.$emit('gotoele')

        // 这里是分会场
      } else {
        if (data.typeId === '3') {
          const item = {
            goodId: data.product_id,
            skuId: data.sku_id,
            product_type: 1
          }

          this.godetail(item)
        } else {
          // 这里是主会场
          const body = {
            couponId: data.id
          }

          if (getBrowserAgent('isWeiXin')) {
            body.uid = localStorage.getItem('uid')
          } else {
            if (this.$route.query.env === '99') {
              body.uid = this.$route.query.mid
            } else {
              body.uid = localStorage.getItem('uid')
            }
          }

          useHallCoupon(body).then((res) => {
            if (Number(res.code) === 200) {
              if (getBrowserAgent('isWeiXin')) {
                this.$router.push('/bVenue?venueId=' + res.data.branchVenueId)
              } else {
                if (this.$route.query.env === '99') {
                  // 这里是跳转RN
                  const Mid = this.$route.query.mid

                  const _Router = {
                    redirectType: 111,
                    name: res.data.name,
                    redirectUrl:
                      'https://web.yuetao.vip/bVenue?venueId=' +
                      res.data.branchVenueId +
                      '&&mid=' +
                      Mid,
                    shareData: res.data.shareData
                  }

                  window.ReactNativeWebView.postMessage(JSON.stringify(_Router))
                } else {
                  // let arr = row.url.split("?");
                  this.$router.push('/bVenue?venueId=' + res.data.branchVenueId)
                }
              }
            }
          })
        }
      }
    },

    godetail(item) {
      this.$store.dispatch('godetail', item)
      const _Router = {
        redirectType: 3,
        id: item.goodId,
        skuId: item.skuId
      }
      switch (item.product_type) {
        case 1:
          _Router.redirectType = 3
          break
        case 3:
          _Router.redirectType = 88
          break
        case 4:
          _Router.redirectType = 95
          break
        case 5:
          _Router.redirectType = 110
          break
        case 6:
          _Router.redirectType = 94
          break
      }

      if (this.$route.query.env === '99') {
        // 这里是跳转RN
        window.ReactNativeWebView.postMessage(JSON.stringify(_Router))
      } else if (this.$route.query.env === '98') {
        // 跳转微信小程序详情
        const goodsId_wx = item.goodId
        const goodsSkuId_wx = item.productSkuId
        const wxUrl =
          `/page/shopDetails/shopDetails?parentTypeId=&goodsId=` +
          goodsId_wx +
          '&skuid=' +
          goodsSkuId_wx
        // eslint-disable-next-line no-undef
        wx.miniProgram.navigateTo({
          url: wxUrl
        })
      } else {
        const obj = urlConversion('obj').values || {}
        if (_Router.redirectType === 3) {
          obj.goodsId = item.goodId
          obj.skuid = item.skuId
          obj.activityId = 75
          this.$router.push({
            path: '/goodsDetailZY',
            query: obj
          })
        } else {
          obj.goodsId = item.goodId
          obj.status = item.product_type
          this.$router.push({
            path: '/goodsDetailCPS',
            query: obj
          })
        }
      }

      // /goodsDetailZY?liveId='直播ID'&liveUserId='直播人ID'&parentTypeId='产品类型'&goodsId='产品ID'&skuid='产品规格ID'
      // this.$router.push({ path: "/goodsDetailMS", query: { goodsId: item.goodId, skuid: item.skuId,activityId:75 } })
    }
  }
}
</script>
<style lang="less" scoped>
.couponBox {
  width: 100%;
  //   padding: 0 7px;
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  margin-bottom: 7px;

  .couponStatus2 {
    position: absolute;
    width: 38px;
    height: 38px;
    top: 6px;
    right: 6px;
  }

  li {
    position: relative;
    margin-right: 4px;

    &:nth-child(4n + 0) {
      margin-right: 0px;
    }

    width: 86px;
    height: 109px;
    background: #fff;
    margin-bottom: 5px;
    padding: 20px 0 0 0;
    line-height: 16px;

    .CouponAmount {
      font-weight: 500;
      font-size: 10px;
      font-family: PingFang SC Medium, PingFang SC Medium-Medium;

      span {
        font-size: 23px;
      }
    }

    .CouponCondition {
      opacity: 0.9;
      font-size: 8px;
      font-family: PingFang SC Medium, PingFang SC Medium-Medium;
      font-weight: 500;
      text-align: center;
      padding: 0 10px;
    }

    .CouponSub {
      opacity: 0.9;
      font-size: 8px;
      font-family: PingFang SC Medium, PingFang SC Medium-Medium;
      font-weight: 500;
      text-align: center;
      padding: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .CouponIcon {
      background: #dd2f27;
      border-radius: 15px;
      height: 15px;
      width: 57.5px;
      margin: 0 auto;
      color: #fff;
      font-size: 10px;
      font-family: PingFang SC Regular, PingFang SC Regular-Regular;
      font-weight: 400;
      text-align: center;
    }
  }

  .couponLiBox {
    .CouponAmount {
      color: #dd2f27;
    }

    .CouponCondition {
      color: #000000;
    }

    .CouponSub {
      color: #3d3d3d;
    }
  }

  .NoCouponLiBox {
    .CouponAmount {
      color: #adadad;
    }

    .CouponCondition {
      color: #adadad;
    }

    .CouponSub {
      color: #adadad;
    }
  }

  .CouponLibgA {
    background: url("../../../assets/images/CouponLibgA.png") no-repeat;
    background-size: 100%;
  }

  .CouponLibgB {
    background: url("../../../assets/images/CouponLibgB.png") no-repeat;
    background-size: 100%;
  }
}
</style>
